<div class="summary">
    <div class="summary-section">
        <img src="../../assets/IMG_8063BW.jpg">
        <div class="summary-text">
            <h1>About Me</h1>
            <p>I am a highly motivated individual 
            with 10 years of professional experience.
            Some of the positions I have held are Customer Service Representative,
            Manager, and Salesman. Currently I am
            looking to fulfill my dream of becoming a web developer. 
            I am a quick learner, and would make a great addition
            <br>to any team.<br>
            <br>I have experience in multiple programming <br>languages, 
            including HTML, CSS, PHP, <br>and Javascript. I am also 
            knowledgeable <br>in some CMS softwares, and <br>can 
            manage a website.
            </p>
        </div>
    </div>
</div>
<article>
    <div class="WorkHistory">
        <h1>Work History</h1>
        <p>I started off my career as a printer.
        My first real job was a part-time employee at 
        Greenfield CopyCat Print Shop. There I designed, and printed
        customers print jobs such as brochures, posters
        booklets, and more. I continued to be a part-time
        employee there for 4 years.</p><br>
        <p>At 
        the end of these four years I was offered a full-time position as the 
        assistant manager. I had much the same duties
        in this position as I did before except now I would 
        be in charge of the shop when the manager was out. 
        I held this position for about a year when the manager
        at that time moved away, and I was recommended to
        be the new manager.</p>
        <br>
        <p> I accepted the role of manager, and 
        ran the shop for a year. I was responsible for hiring and 
        training the employees, making sure that customers print
        jobs went out on time, keeping the business profitable, along 
        with all of the other many tasks that are handled by the 
        manager. Although the company was great to work for I felt
        there was something else out there for me. I thought it was time to 
        move on from that job.</p>
        <br> 
        <p>I found that the Channing Bete Company was
        hiring customer service representatives. I had heard how great of
        a company this was, and there was many avenues of business for me
        to explore. I applied for the job, and was accepted. I worked as a 
        csr for almost 3 years. throughout that time many tasks were added to my work
        load. When I first started I was just answering phones, handling customer questions, and entering orders. Soon enough
        I was trained and put on the team to distribute the electronic products
        we sold. I was then given the assignment of handling our international customers,
        and processing our sales department's orders. During this time I decided to 
        advance my education by taking some classes at Greenfield Community College.
        The company had a great tuition reimbursement program which I utilized
        and gained 17 credits, and a 4.0 grade average.</p>
        <br>
        <p>I was eventually
        asked to join the sales department of our company. I accepted this offer,
        and still work there to this day. In this role I worked with customers to
        meet their deadlines, and ensured complete satisfaction. I would also come
        up with strategies on who to call, and present our products. I went on
        one sales trip to Ohio. There I talked with prospects and 
        customers to see what products we could offer them. In my first year in this position 
        I exceeded my goal by 49%, and am currently on track to exceed this year's goal.</p>
        <br> 
        <p>Sadly the
        Channing Bete Company will be closing in 2019, and I am now looking for new employment.
        I am using this opportunity to fulfill my dream of being a web developer. I have
        had the interest for years, but never acted on it. Now is that time.
        </p>
    </div>
</article>
<article>
<div class="Education">
        <h1>Education</h1>
        <p>I attended Greenfield Community College from 2017-2018. In this time I accumulated 17 credits
        towards an associate's degree, and held a GPA of 4.0. I was also put on the President's
        list for the fall semester of 2018.</p>
        </div>
    </article>
<article>
    <div class="Travel">
        <h1>Travel Experience</h1>
        <p>As I mentioned in my work history I have gone on a sales trip to Ohio meeting with customers and
        prospects in Cleveland, and Columbus. I was also able to travel to Germany and the Czech Republic. I did this
        when I was part of the Boston Youth Symphony Orchestra. In those two countries we visited, and performed in cities such as Leipzig,
        and Prague.          
        </p>
    </div>
</article>